//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CorePlus',
  props: {
    shortCard: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: true,
    },
    includedInSub: {
      type: Boolean,
      default: true,
    },
    discountedPrice: {
      type: String,
      default: '',
    },
    upgradeOffer: {
      type: String,
      default: '',
    },
  },
};
