//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SubscriptionOptions',
  props: {
    threeCards: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      openTab: 1,
      showModal: false,
    };
  },
  methods: {
    toggleTabs (tabNumber) {
      this.openTab = tabNumber;
    },
    goToPage (page) {
      this.$router.push(page);
    },
  },
};
