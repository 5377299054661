//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AllAccess',
  props: {
    bestValue: {
      type: Boolean,
      default: false,
    },
    shortCard: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: true,
    },
    group: {
      type: Boolean,
      default: false,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
    includedInSub: {
      type: Boolean,
      default: true,
    },
    discountedPrice: {
      type: String,
      default: '',
    },
    upgradeOffer: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    rewards: {
      type: Boolean,
      default: false,
    },
    includeHEP: {
      type: Boolean,
      default: false,
    },
  },
};
